<template>
    <div>
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <el-row v-else>
            <el-col class="flag-list" v-for="(m, index) in list">
                <router-link :to="{ path: '/actdes', query: { id: m.id } }">
                    <el-skeleton :loading="loading" animated :count="1">
                        <template slot="template">
                            <el-skeleton-item variant="image" class="img" />
                            <div class="content">
                                <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                                <el-skeleton-item variant="text" style="width: 50%;" />
                            </div>
                            <div class="bottom flex-bt">
                                <el-skeleton-item variant="text" style="width: 30%;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </template>
                        <template>
                            <el-image class="img" :src="rImg(m.images)" fit="cover">
                                <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                            </el-image>
                            <div class="content">
                                <div class="title ellipsis-2">{{m.content}}</div>
                                <div class="tag-box"><span class="tag">#{{m.pay==0?'发起方':'报名者'}}买单</span></div>
                                <p class="area"> <i class="iconfont icon-zhiyuandidian6"></i> {{m.address_name}} </p>
                            </div>
                            <div class="bottom flex-bt">
                                <div class="user" v-if="m.user">
                                    <img :src="rImg(m.user.head_portrait)" :onerror="txImg" class="tx" alt="" />
                                    <span class="name ellipsis-1">{{m.user.name}}</span>
                                </div>
                                <div class=" flex">
                                    <i class="iconfont icon-ren"></i>
                                    <span class="see">{{m.join}}</span>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </router-link>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'actList',
        components: {},
        props: ['list'],
        data() {
            return {
                loading: false,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {},
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';
</style>