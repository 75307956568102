<template>
  <div class="wrap">
    <sNav :list="nav" @setNav="setNav"></sNav>
    <actList :list="list" v-infinite-scroll="getData"></actList>
  </div>
</template>

<script>
import sNav from '@components/common/nav';
import actList from '@components/common/act-list';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    sNav,
    actList
  },
  data() {
    return {
      nav: [],
      list: [],
      navId: 0
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
  },
  mounted() {
    // 在组件被挂载之后调用。
    this.getNav();
    this.getData(1);
  },
  methods: {
    // 切换栏目
    setNav(m) {
      this.navId = m.id;
      this.getData(1);
    },
    async getNav() {
      // 获取邀约活动的子栏目
      let { data } = await this.$y_ajax('api/activity/category');
      data.unshift({
        title: '全部',
        id: 0
      });
      this.nav = data;
    },
    // 获取列表
    async getData(first) {
      let { city } = this.$y_getKey('cityInfo') || {};
      let param = {
        nav: 1, // 1全部|2同城|3关注
        category: this.navId, // 分类
        city: city,
        pay: 0, //0不限|1发起者|2报名者
        sex: this.navId // 0不限|1男|2女
      };
      if (first) {
        param.page = 1;
      }
      this.list = await this.$y_list('api/activity/indexul', this.list, param);
    }
  },
  computed: {
    // 计算属性
    ...mapGetters(['s_getUser', 's_getCity'])
  },
  watch: {
    s_getCity() {
      this.getData(1);
    },
    // 监听
    s_getUser(n) {
      this.getData(1);
    }
  }
};
</script>

<style lang="less" scoped></style>
